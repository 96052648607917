import store from "../store/index.js"
import {MIN_BRUTTO_AUSZAHLUNG} from "../store/constants"

export default {
    data() {
        return {
            anredeForm: anrede ? "Sie" : "Du"
        }
    },
    methods: {
        returnHtml: function (jsonEntry) {
            return jsonEntry
        },
        addCommas: function (nStr) {
            nStr += ""
            const x = nStr.split(".")
            let x1 = x[0]
            const x2 = x.length > 1 ? "." + x[1] : ""
            const rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, "$1" + "." + "$2")
            }
            return x1 + x2
        },
        yearsAndMonths: function (m) {
            const years = m / 12
            const fullYears = parseInt(years)
            const months = m % 12
            if (months === 0) {
                if (fullYears === 1) {
                    return `${fullYears} Jahr`
                } else {
                    return `${fullYears} Jahren`
                }
            } else if (months === 1) {
                if (fullYears === 1) {
                    return `${fullYears} Jahr und 1 Monat`
                } else {
                    return `${fullYears} Jahren und 1 Monat`
                }
            } else {
                if (fullYears === 1) {
                    return `${fullYears} Jahr und ${months} Monaten`
                } else {
                    return `${fullYears} Jahren und ${months} Monaten`
                }
            }
        }
    },
    computed: {
        globalStore() {
            return store.getters.getState
        },
        txtString() {
            // polite speech
            if (anrede) {
                return {
                    general: {
                        headline: "Ihr Wunschziel",
                        button: {
                            forward: "Weiter",
                            back: "Zurück",
                            calculate: "Berechnen",
                            more: "Weitere Details",
                            reset: "Zurücksetzen"
                        },
                        calculators: {
                            prePension: "Vorruhestand",
                            timeout: "Auszeit",
                            parttime: "Teilzeit",
                            pension: "Geld im Alter"
                        }
                    },
                    step1: {
                        left: {
                            info: "Was ist Ihr <strong>Wunschziel</strong>?"
                        },
                        right: {
                            headline: "So einfach geht's",
                            text: "Mit wenigen Schritten können Sie Ihr Ziel mit dem Kalkulator errechnen."
                        }
                    },
                    step2: {
                        left: {
                            slider1: "<strong>In</strong> wie vielen <strong>Jahren</strong> möchten Sie eine Auszeit nehmen?",
                            slider2:
                                "<strong>In</strong> wie vielen <strong>Jahren</strong> möchten Sie in Teilzeit gehen?",
                            slider3:
                                "Auf wie viele Stunden soll Ihre wöchentliche Arbeitszeit <strong>in Stunden</strong> reduziert werden?",
                            slider4:
                                "Wie viele <strong>Monate</strong> soll Ihre Auszeit dauern?",
                            slider5:
                                "Wie viele <strong>Monate</strong> soll Ihre Teilzeit dauern?",
                            slider6:
                                "In welchem <strong>Alter</strong> möchten Sie in den Vorruhestand gehen?",
                            slider7:
                                "Wieviel <strong>% Ihres Gehalts</strong> wollen Sie in dieser Zeit erhalten?",
                            textinput1:
                                "Wieviel <strong>Gehalt</strong> wollen Sie in dieser Zeit erhalten?",
                            slider8:
                                "<strong>Mit welchem Alter</strong> wollen Sie in den <strong>Ruhestand</strong> gehen?",
                            slider9:
                                "Wie hoch soll die <strong>zusätzliche Rente</strong> sein?",
                            wrongInputText: `Bitte nur Zahlen eintragen, mind. jedoch ${MIN_BRUTTO_AUSZAHLUNG} Euro`
                        },
                        right: {
                            headline: "So einfach geht's",
                            text: "Mit wenigen Schritten können Sie Ihr Ziel mit dem Kalkulator errechnen."
                        }
                    },
                    step3: {
                        headline: "Ihre Daten",
                        modal: {
                            headlineOver130: "Ihr Sparziel ist leider zu hoch",
                            headlineUnder70: "Mindest-Sparrate im letzten Sparjahr erforderlich",
                            textOver130: `Aufgrund einer sozialversicherungsrechtlichen Regelung muss das ${this.globalStore.aktives_ziel === "auszeit" ? "Auszeitgehalt" : this.globalStore.aktives_ziel === "rente" ? "Vorruhestandgehalt" : "Teilzeitgehalt"} zwischen 70 – 130 % des regulären Bruttogehaltes nach Abzug der Sparrate im letzten Sparjahr liegen. Hieraus ergibt sich für das letzte Sparjahr eine monatliche Mindest- und Maximal-Sparrate vom Bruttogehalt.`,
                            textUnder70: `Aufgrund einer sozialversicherungsrechtlichen Regelung muss das ${this.globalStore.aktives_ziel === "auszeit" ? "Auszeitgehalt" : this.globalStore.aktives_ziel === "rente" ? "Vorruhestandgehalt" : "Teilzeitgehalt"} zwischen 70 – 130 % des regulären Bruttogehaltes nach Abzug der Sparrate im letzten Sparjahr liegen. Hieraus ergibt sich für das letzte Sparjahr eine monatliche Mindest- und Maximal-Sparrate vom Bruttogehalt.`,
                            textUnder70_2: `Um im letzten Sparjahr flexibel sparen zu können, musst du dein Sparziel auf mindestens ${Math.round(
                                this.globalStore.letztes_gehalt * 0.7
                            )} € Bruttogehalt/Monat für ${
                                this.globalStore.aktives_ziel === "auszeit"
                                    ? "die geplante Auszeit"
                                    : this.globalStore.aktives_ziel === "rente"
                                        ? "den geplanten Vorruhestand"
                                        : "die geplante Teilzeit"
                            } erhöhen.`,
                            table: {
                                label1: "Ihr reguläres Bruttogehalt im letzten Sparjahr:",
                                label2: "Ihr gewünschtes monatliches Brutto-Auszahlungsgehalt:",
                                label3: "Berechnung der Mindest-Sparrate:",
                                label4: `Bruttogehalt - ( Brutto-${
                                    this.globalStore.aktives_ziel === "auszeit"
                                        ? "Auszeitgehalt"
                                        : this.globalStore.aktives_ziel === "rente"
                                            ? "Vorruhestandgehalt"
                                            : "Teilzeitgehalt"
                                } / 70 %)`,
                                label5: "Ihre Mindest-Sparrate im letzten Sparjahr:",
                                label6: "Ihr restliches Bruttogehalt im letzten Sparjahr:"
                            },
                            sparRateTitel: "Ihre monatliche Sparrate:",
                            buttonSparzielaendern: "Sparziel ändern",
                            buttonZumErgebnis: "Zum Ergebnis",
                            over130Labels: {
                                label1: `Gewünschtes Sparziel für ${
                                    this.globalStore.aktives_ziel === "auszeit"
                                        ? "Ihre Auszeit"
                                        : this.globalStore.aktives_ziel === "rente"
                                            ? "Ihren Vorruhestands"
                                            : "Ihre Teilzeit"
                                }:`,
                                label2: "Ihr Bruttogehalt im letzten Sparjahr:",
                                label3: `Maximales Sparziel für ${
                                    this.globalStore.aktives_ziel === "auszeit"
                                        ? "Ihre Auszeit"
                                        : this.globalStore.aktives_ziel === "rente"
                                            ? "Ihren Vorruhestands"
                                            : "Ihre Teilzeit"
                                }:`,
                                label4: "130 % Ihres Bruttogehalts im letzten Sparjahr"
                            }
                        },
                        left: {
                            select1: "Wann ist Ihr <strong>Geburtstag</strong>?",
                            select3: "Besitzen Sie ein <strong>Überstundenkonto</strong>?",
                            slider1:
                                "Wie viele <strong>Stunden</strong> arbeiten Sie pro <strong>Woche</strong>?",
                            slider2: "Welche <strong>Steuerklasse</strong> haben Sie?",
                            textinput1:
                                "Wie hoch ist Ihr <strong>Bruttogehalt/Monat</strong>?",
                            select_arbeitgeberzuschuss: "Wie hoch ist Ihr monatlicher <strong>Arbeigeberzuschuss</strong>?",
                        },
                        right: {
                            headline: "Weitere Details zur Berechnung",
                            text: "Für eine detaillierte Auswertung können Sie weitere Daten wie z.B. Änderung der Rendite, bestehendes Guthaben zur Berechnung eintragen."
                        }
                    },
                    step4: {
                        headline: "Ihre Stammdaten",
                        left: {
                            select1: "Bundesland",
                            select2: "Geschlecht",
                            select3: "Kinder",
                            select4: "gesetzlich versichert",
                            select5: "Kirchensteuer",
                            select6: "Teilnahmebeginn",

                            textinput1: "privat versichert (Betrag)",
                            textinput2: "Steuerfreibetrag"
                        },
                        right: {
                            headline: "Einzahlung / Anlage",
                            textinput1: "Vorhandenes Guthaben",
                            textinput2: "Arbeitgeberzuschuss im Monat",
                            textinput3: "Jährliche Gehaltssteigerung in %",
                            textinput4: "Rendite in %",
                            select: "Sterbetafel",
                            text: "Mit wenigen Schritten kannst du dein Ziel mit dem Kalkulator errechnen."
                        }
                    },
                    step5: {
                        headline: "Ihre Investition",
                        left: {
                            slider1: "<strong>Urlaubstage</strong> (im Jahr)",
                            slider2: "<strong>Überstunden</strong> (im Monat)",
                            slider3: "<strong>Renditeerwartung</strong> (pro Jahr)",
                            slider4: "<strong>Arbeitgeberzuschuss</strong> (im Monat)",
                            slider5: `<strong>vom Gehalt${
                                this.globalStore.andere_rate_fuer_sparen_gehalt_letzes_jahr
                                    ? " bis letztes Sparjahr"
                                    : ""
                            }</strong> (im Monat) Brutto sparen, <strong>${this.addCommas(
                                this.globalStore.netto_zahlen_monat
                            )} €</strong> netto zahlen`,
                            sliderLastYear: `<strong>vom Gehalt im letzten Sparjahr</strong> (im Monat) Brutto sparen, <strong>${this.addCommas(
                                this.globalStore.netto_zahlen_monat_last_year
                            )} €</strong> netto zahlen`,
                            slider5Disabled: "Hinweis zu den gesetzlichen Regelungen",
                            slider5DiabledPopupTitle: "Rechtliche Hinweise",
                            slider5DisabledPopup: `Sie haben die sogenannten "70 - 130 % - Grenze" erreicht. Das Sozialgesetz gibt vor, daß Ihr Auszeitgehalt nicht mehr als 130 % und nicht weniger als 70 % Ihres durchschnittlichen Bruttogehalts während des letzten Ansparjahres abzüglich der Sparrate vom Gehalt sein darf. Sie können wenn möglich die Anspardauer oder das Auszahlungsziel verändern,  ${
                                this.globalStore.theme.options.urlaub
                                    ? "mehr Urlaubstage" +
                                    (this.globalStore.theme.options.ueberstunden &&
                                    this.globalStore.theme.options.sparen_jahr
                                        ? ", "
                                        : this.globalStore.theme.options.ueberstunden ||
                                        this.globalStore.theme.options.sparen_jahr
                                            ? "oder"
                                            : " ")
                                    : ""
                            }${
                                this.globalStore.theme.options.ueberstunden
                                    ? "mehr Überstunden " +
                                    (this.globalStore.theme.options.sparen_jahr ? "oder " : " ")
                                    : " "
                            }${
                                this.globalStore.theme.options.sparen_jahr
                                    ? "mehr Sonderzahlungen "
                                    : " "
                            } einbringen.`,
                            sparzielNotPossibleTitle: "Sparziel nicht möglich",
                            sparzielNotPossible:
                                "Ihr Sparziel kann mit den eingegebenen Daten nicht berechnet werden. Bitte korrigieren Sie ihr Sparziel.",
                            slider6: `<strong>Sonderzahlung</strong> (im Jahr) Brutto sparen, <strong>${this.addCommas(
                                this.globalStore.netto_zahlen_sonderzahlungJahr
                            )} €</strong> netto zahlen`,
                            arbeitgeberzuschuss_description: `Das Ergebnis beinhaltet einen <strong>Arbeitgeberzuschuss</strong> in Höhe von <strong>${this.addCommas(
                                this.globalStore.zuschuss_arbeitgeber
                            )} € / Monat</strong> `,
                        },
                        right: {
                            headline: "Ihr Ergebnis",
                            button: {
                                pdf: "PDF",
                                details: "DETAILS"
                            },
                            textinput1: "Jahre/Monate bis zum Ziel",
                            textinput2: "Monate Auszeit",
                            textinput3: "Jahre/Monate bis zur Teilzeit",
                            textinput4: "Monate Teilzeit",
                            textinput5: "Beginn Vorruhestand",
                            textinput6: "Rentenalter",
                            textinput7: "Zusätzliche Rente/Monat",
                            note: "Bei allen Berechnungen handelt es sich um modellhafte Darstellungen und erfolgen ohne Gewähr. Zudem erfolgt keine steuerliche Beratung."
                        },
                        result: {
                            isLess: `Leider haben sie Ihr Ziel erst zu <strong>${this.globalStore.anzeige_prozent} %</strong> erreicht.`,
                            equals: "Sie haben Ihr <strong>Ziel erreicht</strong>.",
                            isGreater: {
                                tippAuszeit:
                                    "Beginnen Sie früher mit der Auszeit oder verlängern Sie diese um den Überschuss zu verringern.",
                                tippTeilzeit:
                                    "Beginnen Sie früher mit der Teilzeit oder verlängern Sie diese um den Überschuss zu verringern.",
                                tippRente:
                                    "Beginnen Sie früher mit dem Vorruhestand um den Überschuss zu verringern.",
                                pension: `Sie haben ihr Ziel erreicht und sogar  <strong>${this.addCommas(
                                    Math.floor(
                                        (this.globalStore.kapital +
                                            this.globalStore.rendite_ueberschuss_rente -
                                            this.globalStore.kapital_notwendig) /
                                        100
                                    ) * 100
                                )} €</strong> Überschuss.`,
                                time: `Sie haben Ihr Ziel erreicht und sogar  <strong>${this.addCommas(
                                    Math.floor(
                                        (this.globalStore.kapital - this.globalStore.zielkapital) /
                                        100
                                    ) * 100
                                )} €</strong> Überschuss.`
                            }
                        }
                    },
                    modal: {
                        headline: "Details zu diesem Ergebnis",
                        textTop: ` Mit ${this.addCommas(
                            this.globalStore.details.input
                        )} € Nettoeinsatz erreichen Sie eine Summe von ${this.addCommas(
                            this.globalStore.details.output
                        )} €. <p>Und so setzt sich dieses Gesamtguthaben zusammen:</p>`,
                        left: {
                            vacation: "Urlaubstage*",
                            overtime: "Überstunden*",
                            saveMonth: "vom Gehalt*",
                            saveYear: "aus Sonderzahlungen*",
                            return: "Rendite*",
                            taxes: "Steuern- &amp; SV-Ersparnis*",
                            employerSub: "Arbeitgeberzuschuss*",
                            credit: "Vorhandenes Guthaben*",
                            note: "* Schematische Darstellung: aus Vereinfachungsgründen wird auf volle 100 € gerundet. Steuer- & SV-Ersparnis in der Ansparphase.",
                            notePension: `**Mtl. Brutto-Rente bei einer Lebenserwartung bis zum Alter ${this.globalStore.giaAlter} und einer Verzinsung des Gesamtkapitals von ${this.globalStore.rendite} % während der Auszahlungsphase.`
                        },
                        right: {
                            text: `Dank dieser Sparbausteine erreichen Sie mit gerade einmal ${this.addCommas(
                                this.globalStore.details.input
                            )} € Nettoeinsatz (vorhandenes Guthaben, monatliches Gehalt &amp; Sonderzahlungen) in ${this.yearsAndMonths(
                                this.globalStore.ansparzeit_monate
                            )} bei einer konservativen Rendite von ${
                                this.globalStore.rendite
                            } % ein Brutto-Gesamtkapital von über ${this.addCommas(
                                this.globalStore.details.output
                            )} €.`
                        }
                    },
                    pdf: {
                        headline: "Ihr Wunschziel:",
                        headlineAdoption: "Annahmen",
                        headlineBaseData: "Stammdaten",
                        headlineDetails: "Details zu Ihrem Ergebnis*",
                        headlineResult: "Ihr Ergebnis",
                        intro: {
                            prePension: `Mit ${this.globalStore.wunsch_rentenalter} Jahren in den Vorruhestand gehen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
                            timeOut: `In ${this.globalStore.auszeit_in_jahre} Jahren eine Auszeit nehmen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
                            parttime: `In ${this.globalStore.teilzeit_in_jahre} Jahren in Teilzeit gehen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
                            pension: `Mit ${this.globalStore.gia_alter}} Jahren in Rente gehen und dabei monatlich ${this.globalStore.zusatzrente} Euro zusätzlich zur Rente erhalten.`
                        },
                        description:
                            "Nachfolgend erhalten Sie die Zusammenfassung Ihrer Angaben und das Ergebnis der Berechnung.",
                        columns: {
                            born: "Geboren am",
                            hours: "Wochenstunden",
                            gSalary: "Brutto-Gehalt im Monat",
                            taxClass: "Steuerklasse",
                            state: "Bundesland",
                            curchTax: "Kirchensteuer",
                            insurance: {
                                txt: "Krankenversicherung",
                                private: "Privat",
                                statutory: "Gesetzlich"
                            },
                            credit: "Vorhandenes Guthaben",
                            employerSub: "Arbeitgeberzuschuss im Monat",
                            return: "Renditeerwartung p. a.",
                            salaryTrend: "Gehaltstrend p. a.",
                            startParti: "Teilnahmebeginn",
                            partTimeWorkload: "Zielstunden in Teilzeit",
                            vacationYear: "Urlaubstage (im Jahr)",
                            overtimeMonth: "Überstunden (im Monat)",
                            saveMonth: "Monatsgehalt brutto sparen (netto zahlen)",
                            saveMonthLastYear:
                                "Monatsgehalt letztes Sparjahr brutto sparen (netto zahlen)",
                            saveYear: "Sonderzahlungen im Jahr brutto sparen (netto zahlen)",
                            prePension: {
                                start: "Beginn des Vorruhestands",
                                startLegally: "Beginn gesetzliche Rente",
                                end: "Ende des Vorruhestands",
                                payment: " Gehalt während des Vorruhestands",
                                excess: "Überschuss (+) / Unterdeckung (-)"
                            },
                            timeOut: {
                                start: "Beginn der Auszeit",
                                end: "Ende der Auszeit",
                                payment: "Gehalt während der Auszeit",
                                excess: "Überschuss (+) / Unterdeckung (-)"
                            },
                            partTime: {
                                start: "Beginn der Teilzeit",
                                end: "Ende der Teilzeit",
                                payment: "Gehalt während der Teilzeit",
                                excess: "Überschuss (+) / Unterdeckung (-)"
                            },
                            pension: {
                                start: "Beginn der Rente",
                                end: "Ende der Laufzeit",
                                payment: "Zusatzrente",
                                excess: "Überschuss (+) / Unterdeckung (-)"
                            },
                            total: {
                                capital: "Gesamtkapital",
                                vacation: "Urlaubstage (im Jahr) / Summe",
                                overtime: "Überstunden (im Monat) / Summe",
                                saveMonth: "Monatsgehalt brutto sparen (netto zahlen) / Summe",
                                saveMonthLastYear:
                                    "Monatsgehalt letztes Sparjahr brutto sparen (netto zahlen) / Summe",
                                saveYear: "Sonderzahlungen (pro Jahr, netto zahlen) / Summe",
                                return: "Rendite in Summe",
                                tax: "Steuer- und Sozialversicherungsersparnis in Summe",
                                employerSub: "Arbeitgeberzuschuss im Monat in Summe"
                            }
                        },
                        summary: {
                            text: `Dank dieser Sparbausteine erreichen Sie mit gerade einmal ${this.addCommas(
                                this.globalStore.details.input
                            )} € Nettoeinsatz (vorhandenes Guthaben, monatliches Gehalt &amp; Sonderzahlungen) in ${this.yearsAndMonths(
                                this.globalStore.ansparzeit_monate
                            )} bei einer konservativen Rendite von ${
                                this.globalStore.rendite
                            } % ein Brutto-Gesamtkapital von über ${this.addCommas(
                                this.globalStore.details.output
                            )} €.`,
                            prePension: `Damit können Sie es sich gönnen, mit ${this.globalStore.wunsch_rentenalter} Jahren in Vorruhestand zu gehen und das bei weiterhin <span>${this.globalStore.gehalt_prozent}</span> Prozent Ihres letzten Gehalts.`,
                            timeOut: `Damit können Sie es sich gönnen, in ${this.globalStore.auszeit_in_jahre} Jahren eine Auszeit zu nehmen und das bei weiterhin <span>${this.globalStore.gehalt_prozent} Prozent</span> Ihres letzten Gehalts.`,
                            partTime: `Damit können Sie es sich gönnen, in ${this.globalStore.teilzeit_in_jahre} Jahren in Teilzeit zu gehen. Und das bei weiterhin <span> ${this.globalStore.gehalt_prozent} Prozent</span> Ihres letzten Gehalts.`,
                            pension: `Damit können Sie es sich gönnen, mit ${
                                this.globalStore.gia_alter
                            } Jahren in Rente zu gehen und erhalten dabei jeden Monat zusätzlich <span> ${this.addCommas(
                                this.globalStore.zusatzrente
                            )} Euro</span> zu deiner Rente.`
                        },
                        footer: {
                            p1: "<strong>Für mehr Informationen</strong> wenden Sie sich an uns:",
                            p2: `Telefon: <strong>${this.globalStore.theme.contact.phone}</strong> oder E-Mail: <strong>${this.globalStore.theme.contact.mail}</strong>`,
                            note1:
                                "Bitte beachten Sie, dass es sich um eine Modellberechnung handelt, die ohne Gewähr erfolgt.",
                            note2: "Auch handelt es sich hier nicht um steuerliche Beratung.",
                            created: `Erstellt am ${this.globalStore.datum}`
                        },
                        units: {
                            hour: {
                                single: "Stunde",
                                multi: "Stunden"
                            },
                            day: {
                                single: "Tag",
                                multi: "Tage"
                            }
                        }
                    }
                }
            }

            // personal speech
            else {
                return {
                    general: {
                        headline: "Dein Wunschziel",
                        button: {
                            forward: "Weiter",
                            back: "Zurück",
                            calculate: "Berechnen",
                            more: "Weitere Details",
                            reset: "Zurücksetzen"
                        },
                        calculators: {
                            prePension: "Vorruhestand",
                            timeout: "Auszeit",
                            parttime: "Teilzeit",
                            pension: "Geld im Alter"
                        }
                    },
                    step1: {
                        left: {
                            info: "Was ist dein <strong>Wunschziel</strong>?"
                        },
                        right: {
                            headline: "So einfach geht's",
                            text: "Mit wenigen Schritten kannst du dein Ziel mit dem Kalkulator errechnen."
                        }
                    },
                    step2: {
                        left: {
                            slider1: "<strong>In</strong> wie vielen <strong>Jahren</strong> möchtest du dir eine Auszeit nehmen?",
                            slider2:
                                "<strong>In</strong> wie vielen <strong>Jahren</strong> möchtest du in Teilzeit gehen?",
                            slider3:
                                "Auf wie viele Stunden soll deine wöchentliche Arbeitszeit <strong>in Stunden</strong> reduziert werden?",
                            slider4:
                                "Wie viele <strong>Monate</strong> soll deine Auszeit dauern?",
                            slider5:
                                "Wie viele <strong>Monate</strong> soll deine Teilzeit dauern?",
                            slider6:
                                "In welchem <strong>Alter</strong> möchtest du in den Vorruhestand gehen?",
                            slider7:
                                "Wieviel <strong>% deines Gehalts</strong> willst du in dieser Zeit erhalten?",
                            textinput1:
                                "Wieviel <strong>Gehalt</strong> willst du in dieser Zeit erhalten?",
                            slider8:
                                "<strong>Mit welchem Alter</strong> willst du in den <strong>Ruhestand</strong> gehen?",
                            slider9:
                                "Wie hoch soll die <strong>zusätzliche Rente</strong> sein?",
                            wrongInputText: `Bitte nur Zahlen eintragen; mind. jedoch ${MIN_BRUTTO_AUSZAHLUNG} Euro`
                        },
                        right: {
                            headline: "So einfach geht's",
                            text: "Mit wenigen Schritten kannst du dein Ziel mit dem Kalkulator errechnen."
                        }
                    },
                    step3: {
                        headline: "Deine Daten",
                        modal: {
                            headlineOver130: "Dein Sparziel ist leider zu hoch",
                            headlineUnder70:
                                "Mindest-Sparrate im letzten Sparjahr erforderlich",
                            textOver130: `Aufgrund einer sozialversicherungsrechtlichen Regelung muss das ${this.globalStore.aktives_ziel === "auszeit" ? "Auszeitgehalt" : this.globalStore.aktives_ziel === "rente" ? "Vorruhestandgehalt" : "Teilzeitgehalt"} zwischen 70 – 130 % des regulären Bruttogehaltes nach Abzug der Sparrate im letzten Sparjahr liegen. Hieraus ergibt sich für das letzte Sparjahr eine monatliche Mindest- und Maximal-Sparrate vom Bruttogehalt.`,
                            textUnder70: `Aufgrund einer sozialversicherungsrechtlichen Regelung muss das ${this.globalStore.aktives_ziel === "auszeit" ? "Auszeitgehalt" : this.globalStore.aktives_ziel === "rente" ? "Vorruhestandgehalt" : "Teilzeitgehalt"} zwischen 70 – 130 % des regulären Bruttogehaltes nach Abzug der Sparrate im letzten Sparjahr liegen. Hieraus ergibt sich für das letzte Sparjahr eine monatliche Mindest- und Maximal-Sparrate vom Bruttogehalt.`,
                            textUnder70_2: `Um im letzten Sparjahr flexibel sparen zu können, müssen Sie ihr Sparziel auf mindestens ${Math.round(
                                this.globalStore.bruttogehalt_monat * 0.7
                            )} € Bruttogehalt/Monat für ${
                                this.globalStore.aktives_ziel === "auszeit"
                                    ? "die geplante Auszeit"
                                    : this.globalStore.aktives_ziel === "rente"
                                        ? "den geplanten Vorruhestand"
                                        : "die geplante Teilzeit"
                            } erhöhen.`,
                            table: {
                                label1: "Dein reguläres Bruttogehalt im letzten Sparjahr:",
                                label2: "Dein gewünschtes monatliches Brutto-Auszahlungsgehalt:",
                                label3: "Berechnung der Mindest-Sparrate:",
                                label4: `Bruttogehalt - ( Brutto-${
                                    this.globalStore.aktives_ziel === "auszeit"
                                        ? "Auszeitgehalt"
                                        : this.globalStore.aktives_ziel === "rente"
                                            ? "Vorruhestandgehalt"
                                            : "Teilzeitgehalt"
                                } / 70 %)`,
                                label5: "Deine Mindest-Sparrate im letzten Sparjahr:",
                                label6: "Dein restliches Bruttogehalt im letzten Sparjahr:"
                            },
                            sparRateTitel: "Deine monatliche Sparrate:",
                            buttonSparzielaendern: "Sparziel ändern",
                            buttonZumErgebnis: "Zum Ergebnis",
                            over130Labels: {
                                label1: `Gewünschtes Sparziel für ${
                                    this.globalStore.aktives_ziel === "auszeit"
                                        ? "deine Auszeit"
                                        : this.globalStore.aktives_ziel === "rente"
                                            ? "deinen Vorruhestands"
                                            : "deine Teilzeit"
                                }:`,
                                label2: "Dein Bruttogehalt im letzten Sparjahr:",
                                label3: `Maximales Sparziel für ${
                                    this.globalStore.aktives_ziel === "auszeit"
                                        ? "deine Auszeit"
                                        : this.globalStore.aktives_ziel === "rente"
                                            ? "deinen Vorruhestands"
                                            : "deine Teilzeit"
                                }:`,
                                label4: "130 % deines Bruttogehalts im letzten Sparjahr"
                            }
                        },
                        left: {
                            select1: "Wann ist dein <strong>Geburtstag</strong>?",
                            select3: "Besitzt du ein <strong>Überstundenkonto</strong>?",
                            slider1:
                                "Wie viele <strong>Stunden</strong> arbeitest du pro <strong>Woche</strong>?",
                            slider2: "Welche <strong>Steuerklasse</strong> hast du?",
                            textinput1:
                                "Wie hoch ist dein <strong>Bruttogehalt/Monat</strong>?",
                            select_arbeitgeberzuschuss: "Wie hoch ist dein monatlicher <strong>Arbeigeberzuschuss</strong>?",
                        },
                        right: {
                            headline: "Weitere Details zur Berechnung",
                            text: "Für eine detaillierte Auswertung kannst du weitere Daten wie z.B. Änderung der Rendite, bestehendes Guthaben zur Berechnung eintragen."
                        }
                    },
                    step4: {
                        headline: "Deine Stammdaten",
                        left: {
                            select1: "Bundesland",
                            select2: "Geschlecht",
                            select3: "Kinder",
                            select4: "gesetzlich versichert",
                            select5: "Kirchensteuer",
                            select6: "Teilnahmebeginn",

                            textinput1: "privat versichert (Betrag)",
                            textinput2: "Steuerfreibetrag"
                        },
                        right: {
                            headline: "Einzahlung / Anlage",
                            textinput1: "Vorhandenes Guthaben",
                            textinput2: "Arbeitgeberzuschuss im Monat",
                            textinput3: "Jährliche Gehaltssteigerung in %",
                            textinput4: "Rendite in %",
                            select: "Sterbetafel",
                            text: "Mit wenigen Schritten kannst du dein Ziel mit dem Kalkulator errechnen."
                        }
                    },
                    step5: {
                        headline: "Deine Investition",
                        left: {
                            slider1: "<strong>Urlaubstage</strong> (im Jahr)",
                            slider2: "<strong>Überstunden</strong> (im Monat)",
                            slider3: "<strong>Renditeerwartung</strong> (pro Jahr)",
                            slider4: "<strong>Arbeitgeberzuschuss</strong> (im Monat)",
                            slider5: `<strong>vom Gehalt${
                                this.globalStore.andere_rate_fuer_sparen_gehalt_letzes_jahr
                                    ? " bis letztes Sparjahr"
                                    : ""
                            }</strong> (im Monat) Brutto sparen, <strong>${this.addCommas(
                                this.globalStore.netto_zahlen_monat
                            )} €</strong> netto zahlen`,
                            sliderLastYear: `<strong>vom Gehalt im letzten Sparjahr</strong> (im Monat) Brutto sparen, <strong>${this.addCommas(
                                this.globalStore.netto_zahlen_monat_last_year
                            )} €</strong> netto zahlen`,
                            slider5Disabled: "Hinweis zu den gesetzlichen Regelungen",
                            slider5DiabledPopupTitle: "Rechtliche Hinweise",
                            slider5DisabledPopup: `Du hat die sogenannten "70 - 130 % - Grenze" erreicht. Das Sozialgesetz gibt vor, daß Dein Auszeitgehalt nicht mehr als 130 % und nicht weniger als 70 % deines durchschnittlichen Bruttogehalts im letzten Ansparjahr abzüglich der Sparrate vom Gehalt sein darf. Du kannst wenn auch möglich die Anspardauer oder das Auszahlungsziel verändern, ${
                                this.globalStore.theme.options.urlaub
                                    ? "mehr Urlaubstage" +
                                    (this.globalStore.theme.options.ueberstunden &&
                                    this.globalStore.theme.options.sparen_jahr
                                        ? ", "
                                        : this.globalStore.theme.options.ueberstunden ||
                                        this.globalStore.theme.options.sparen_jahr
                                            ? "oder"
                                            : " ")
                                    : ""
                            }${
                                this.globalStore.theme.options.ueberstunden
                                    ? "mehr Überstunden " +
                                    (this.globalStore.theme.options.sparen_jahr ? "oder " : " ")
                                    : " "
                            }${
                                this.globalStore.theme.options.sparen_jahr
                                    ? "mehr Sonderzahlungen "
                                    : " "
                            } einbringen.`,
                            sparzielNotPossibleTitle: "Sparziel nicht möglich",
                            sparzielNotPossible:
                                "Dein Sparziel kann mit den eingegebenen Daten nicht berechnet werden. Bitte korrigiere dein Sparziel.",
                            slider6: `<strong>Sonderzahlung</strong> (im Jahr) Brutto sparen, <strong>${this.addCommas(
                                this.globalStore.netto_zahlen_sonderzahlungJahr
                            )} €</strong> netto zahlen`,
                            arbeitgeberzuschuss_description: `Das Ergebnis beinhaltet einen <strong>Arbeitgeberzuschuss</strong> in Höhe von <strong>${this.addCommas(
                                this.globalStore.zuschuss_arbeitgeber
                            )} € / Monat</strong> `,
                        },
                        right: {
                            headline: "Dein Ergebnis",
                            button: {
                                pdf: "PDF",
                                details: "DETAILS"
                            },
                            textinput1: "Jahre/Monate bis zum Ziel",
                            textinput2: "Monate Auszeit",
                            textinput3: "Jahre/Monate bis zur Teilzeit",
                            textinput4: "Monate Teilzeit",
                            textinput5: "Beginn Vorruhestand",
                            textinput6: "Rentenalter",
                            textinput7: "Zusätzliche Rente/Monat",
                            note: "Bei allen Berechnungen handelt es sich um modellhafte Darstellungen und erfolgen ohne Gewähr. Zudem erfolgt keine steuerliche Beratung."
                        },
                        result: {
                            isLess: `Leider hast du dein Ziel erst zu <strong>${this.globalStore.anzeige_prozent} %</strong> erreicht.`,
                            equals: "Du hast dein <strong>Ziel erreicht</strong>.",
                            isGreater: {
                                tippAuszeit:
                                    "Beginne früher mit der Auszeit oder verlängere diese um den Überschuss zu verringern.",
                                tippTeilzeit:
                                    "Beginne früher mit der Teilzeit oder verlängere diese um den Überschuss zu verringern.",
                                tippRente:
                                    "Beginne früher mit dem Vorruhestand um den Überschuss zu verringern.",
                                pension: `Du hast dein Ziel erreicht und sogar  <strong>${this.addCommas(
                                    Math.floor(
                                        (this.globalStore.kapital +
                                            this.globalStore.rendite_ueberschuss_rente -
                                            this.globalStore.kapital_notwendig) /
                                        100
                                    ) * 100
                                )} €</strong> Überschuss.`,
                                time: `Du hast dein Ziel erreicht und sogar  <strong>${this.addCommas(
                                    Math.floor(
                                        (this.globalStore.kapital - this.globalStore.zielkapital) /
                                        100
                                    ) * 100
                                )} €</strong> Überschuss.`
                            }
                        }
                    },
                    modal: {
                        headline: "Details zu diesem Ergebnis",
                        textTop: ` Mit ${this.addCommas(
                            this.globalStore.details.input
                        )} € Nettoeinsatz erreichst du eine Summe von ${this.addCommas(
                            this.globalStore.details.output
                        )} €. <p>Und so setzt sich dieses Gesamtguthaben zusammen:</p>`,
                        left: {
                            vacation: "Urlaubstage*",
                            overtime: "Überstunden*",
                            saveMonth: "vom Gehalt*",
                            saveYear: "aus Sonderzahlungen*",
                            return: "Rendite*",
                            taxes: "Steuern- &amp; SV-Ersparnis*",
                            employerSub: "Arbeitgeberzuschuss*",
                            credit: "Vorhandenes Guthaben*",
                            note: "* Schematische Darstellung: aus Vereinfachungsgründen wird auf volle 100 € gerundet. Steuer- & SV-Ersparnis in der Ansparphase.",
                            notePension: `**Mtl. Brutto-Rente bei einer Lebenserwartung bis zum Alter ${this.globalStore.giaAlter} und einer Verzinsung des Gesamtkapitals von ${this.globalStore.rendite} % während der Auszahlungsphase.`
                        },
                        right: {
                            text: `Dank dieser Sparbausteine erreichst du mit gerade einmal ${this.addCommas(
                                this.globalStore.details.input
                            )} € Nettoeinsatz (vorhandenes Guthaben, monatliches Gehalt &amp; Sonderzahlungen) in ${this.yearsAndMonths(
                                this.globalStore.ansparzeit_monate
                            )} bei einer konservativen Rendite von ${
                                this.globalStore.rendite
                            } % ein Brutto-Gesamtkapital von über ${this.addCommas(
                                this.globalStore.details.output
                            )} €.`
                        }
                    },
                    pdf: {
                        headline: "Dein Wunschziel:",
                        headlineAdoption: "Annahmen",
                        headlineBaseData: "Stammdaten",
                        headlineDetails: "Details zu Deinem Ergebnis*",
                        headlineResult: "Dein Ergebnis",
                        intro: {
                            prePension: `Mit ${this.globalStore.wunsch_rentenalter} Jahren in den Vorruhestand gehen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
                            timeOut: `In ${this.globalStore.auszeit_in_jahre} Jahren eine Auszeit nehmen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
                            parttime: `In ${this.globalStore.teilzeit_in_jahre} Jahren in Teilzeit gehen und dabei weiterhin ${this.globalStore.gehalt_prozent} Prozent Gehalt beziehen`,
                            pension: `Mit ${this.globalStore.gia_alter}} Jahren in Rente gehen und dabei monatlich ${this.globalStore.zusatzrente} Euro zusätzlich zur Rente erhalten.`
                        },
                        description:
                            "Nachfolgend erhältst du die Zusammenfassung deiner Angaben und das Ergebnis der Berechnung.",
                        columns: {
                            born: "Geboren am",
                            hours: "Wochenstunden",
                            gSalary: "Brutto-Gehalt im Monat",
                            taxClass: "Steuerklasse",
                            state: "Bundesland",
                            curchTax: "Kirchensteuer",
                            insurance: {
                                txt: "Krankenversicherung",
                                private: "Privat",
                                statutory: "Gesetzlich"
                            },
                            credit: "Vorhandenes Guthaben",
                            employerSub: "Arbeitgeberzuschuss im Monat",
                            return: "Renditeerwartung p. a.",
                            salaryTrend: "Gehaltstrend p. a.",
                            startParti: "Teilnahmebeginn",
                            partTimeWorkload: "Zielstunden in Teilzeit",
                            vacationYear: "Urlaubstage (im Jahr)",
                            overtimeMonth: "Überstunden (im Monat)",
                            saveMonth: "Monatsgehalt brutto sparen (netto zahlen)",
                            saveMonthLastYear:
                                "Monatsgehalt letztes Sparjahr brutto sparen (netto zahlen)",
                            saveYear: "Sonderzahlungen im Jahr brutto sparen (netto zahlen)",
                            prePension: {
                                start: "Beginn des Vorruhestands",
                                startLegally: "Beginn gesetzliche Rente",
                                end: "Ende des Vorruhestands",
                                payment: " Gehalt während des Vorruhestands",
                                excess: "Überschuss (+) / Unterdeckung (-)"
                            },
                            timeOut: {
                                start: "Beginn der Auszeit",
                                end: "Ende der Auszeit",
                                payment: "Gehalt während der Auszeit",
                                excess: "Überschuss (+) / Unterdeckung (-)"
                            },
                            partTime: {
                                start: "Beginn der Teilzeit",
                                end: "Ende der Teilzeit",
                                payment: "Gehalt während der Teilzeit",
                                excess: "Überschuss (+) / Unterdeckung (-)"
                            },
                            pension: {
                                start: "Beginn der Rente",
                                end: "Ende der Laufzeit",
                                payment: "Zusatzrente",
                                excess: "Überschuss (+) / Unterdeckung (-)"
                            },
                            total: {
                                capital: "Gesamtkapital",
                                vacation: "Urlaubstage (im Jahr) / Summe",
                                overtime: "Überstunden (im Monat) / Summe",
                                saveMonth: "Monatsgehalt brutto sparen (netto zahlen) / Summe",
                                saveMonthLastYear:
                                    "Monatsgehalt letztes Sparjahr brutto sparen (netto zahlen) / Summe",
                                saveYear: "Sonderzahlungen (pro Jahr, netto zahlen) / Summe",
                                return: "Rendite in Summe",
                                tax: "Steuer- und Sozialversicherungsersparnis in Summe",
                                employerSub: "Arbeitgeberzuschuss im Monat in Summe"
                            }
                        },
                        summary: {
                            text: `Dank dieser Sparbausteine erreichst du mit gerade einmal ${this.addCommas(
                                this.globalStore.details.input
                            )} € Nettoeinsatz (vorhandenes Guthaben, monatliches Gehalt &amp; Sonderzahlungen) in ${this.yearsAndMonths(
                                this.globalStore.ansparzeit_monate
                            )} bei einer konservativen Rendite von ${
                                this.globalStore.rendite
                            } % ein Brutto-Gesamtkapital von über ${this.addCommas(
                                this.globalStore.details.output
                            )} €.`,
                            prePension: `Damit kannst du es dir gönnen, mit ${this.globalStore.wunsch_rentenalter} Jahren in Vorruhestand zu gehen und das bei weiterhin <span>${this.globalStore.gehalt_prozent}</span> Prozent deines letzten Gehalts.`,
                            timeOut: `Damit kannst du es dir gönnen, in ${this.globalStore.auszeit_in_jahre} Jahren eine Auszeit zu nehmen und das bei weiterhin <span>${this.globalStore.gehalt_prozent} Prozent</span> deines letzten Gehalts.`,
                            partTime: `Damit kannst du es dir gönnen, in ${this.globalStore.teilzeit_in_jahre} Jahren in Teilzeit zu gehen. Und das bei weiterhin <span> ${this.globalStore.gehalt_prozent} Prozent</span> deines letzten Gehalts.`,
                            pension: `Damit kannst du es dir gönnen, mit ${
                                this.globalStore.gia_alter
                            } Jahren in Rente zu gehen und erhälst dabei jeden Monat zusätzlich <span> ${this.addCommas(
                                this.globalStore.zusatzrente
                            )} Euro</span> zu deiner Rente.`
                        },
                        footer: {
                            p1: "<strong>Für mehr Informationen</strong> wende dich an uns:",
                            p2: `Telefon: <strong>${this.globalStore.theme.contact.phone}</strong> oder E-Mail: <strong>${this.globalStore.theme.contact.mail}</strong>`,
                            note1:
                                "Bitte beachte, dass es sich um eine Modellberechnung handelt, die ohne Gewähr erfolgt.",
                            note2: "Auch handelt es sich hier nicht um steuerliche Beratung.",
                            created: `Erstellt am ${this.globalStore.datum}`
                        },
                        units: {
                            hour: {
                                single: "Stunde",
                                multi: "Stunden"
                            },
                            day: {
                                single: "Tag",
                                multi: "Tage"
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
    }
}
