// import Velocity from "velocity-animate"; use this for complex animation
import { Navigation } from '../js-components/navigation';
import { Accordion } from '../js-components/accordion';
import { Collapsible } from "../js-components/collapsible";
import { Animations } from '../js-components/animations';
import { Distancetop } from '../js-components/distancetop';
import { CircleFirefox } from '../js-components/circleFirefox';
import { twoOptionsChange } from '../js-components/twoOptionsChange';
import { spinCards } from '../js-components/spinCards';
import { initializeAnimations } from '../js-components/initializeAnimations';
import { initializeSliders } from '../js-components/sliders';
import { Loadmore } from '../js-components/loadmore';
import { initializeLottieAnimations } from '../js-components/lottieAnimations';
import { wikiAjax } from '../js-components/wikiAjax';
import { archiveWiki } from '../js-components/archiveWiki';
import { pardotFormular } from '../js-components/pardotFormular';

export default {

  init() {
    // JavaScript to be fired on all pages
    // Distancetop();
    Animations();
    initializeAnimations();
    Navigation();
    CircleFirefox();
    //Accordion();
    //Collapsible();
    twoOptionsChange();
    spinCards();
    initializeSliders();
    Loadmore();
    initializeLottieAnimations();
    wikiAjax();
    archiveWiki();
    pardotFormular();
  }
}
